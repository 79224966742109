.home-page-specialist {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
}

.specialist-header {
  background-color: var(--primary-color);
  padding: 20px;
  text-align: center;
  color: var(--text-color);
}

.specialist-header h2 {
  font-size: 24px;
  margin-bottom: 5px;
}

.specialist-header p {
  font-size: 18px;
}

.patients-list {
  flex: 1;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  overflow-y: auto;
}

.patients-list h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.patient-item {
  padding: 10px;
  background-color: var(--secondary-color);
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.patient-item:hover {
  background-color: var(--secondary-color-hover);
}

.footer-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  padding: 10px;
  color: var(--text-color);
}

.footer-button {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.footer-button:hover {
  background-color: var(--secondary-color-hover);
}
