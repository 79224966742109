.home-page-patient {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chat-container {
  flex: 1;
  padding: 20px;
}

.home-page-patient h3 {
  font-size: 20px;
  color: var(--primary-color);
}

@media (max-width: 600px) {
  .home-page-patient {
    padding: 10px;
  }
}
